






import {Component, Vue} from 'vue-property-decorator';
import {CustomerStatus} from "@/constants/Customer";

@Component({})
export default class CellRendererStatus extends Vue {
    params: any;

    get chipColor() {
        return (value: number) => {
            if (value === CustomerStatus.Official) {
                return "success"
            } else if (value === CustomerStatus.Potential) {
                return "warning"
            } else
                return "danger";
        }
    }

    get statusName() {
        return (value: number) => {
            if (value === CustomerStatus.Official) {
                return "Chính thức"
            } else if (value === CustomerStatus.Potential) {
                return "Tiềm năng"
            } else if (value === CustomerStatus.InStore)
                return "Trong kho";
            else
                return ""
        }
    }
}
