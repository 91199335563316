











































































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import { CustomerResult } from "@/models/customer/CustomerResult";
import DropdownCellRenderer from "@/components/ag-grid/DropdownCellRenderer.vue";
import FilterList from "../../components/filter/FilterList.vue";
import SelectPageSize from "../../components/select-page-size/select-page-size.vue";
import { FilterAttributeResult } from "../../models/attribute/FilterAttributeResult";
import { AttributeType, DataType, LogicType, OperatorType } from "../../constants/Attribute";
import { QueryLoadOption, SortingInfo } from "../../models/QueryLoadOptions";
import { CustomerService } from "../../services/customer/CustomerService";
import SettingColumns from "../../components/SettingColumns.vue";
import { CategoryType } from "../../models/category/CategoryType";
import { SettingColumnResult, SettingColumnSelect } from "../../models/settingColumn/SettingColumnResult";
import { SettingColumnService } from "../../services/SettingColumnService";
import CellRendererActive from "./cell-renderer/CellRendererActive.vue";
import CellRendererLink from "./cell-renderer/CellRendererLink.vue";
import { ActionResult } from "../../models/ActionResult";
import FloatingSelectFilter from "../../components/ag-grid/filters/FloatingSelectFilter.vue";
import { namespace } from "vuex-class";
import CellRenderType from "./cell-renderer/CellRenderType.vue";
import CustomValueRowRenderer from "./cell-renderer/CustomValueRowRenderer.vue";
import CellRendererApprove from "./cell-renderer/CellRendererApprove.vue";
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import { FilterStateResult } from "../../models/filter/filter-state-result";
import { SearchResult } from "../../models/SearchResult";
import CellSelectOptionEditor from "@/components/ag-grid/cell-select-option-editor/CellSelectOptionEditor.vue";
import { CategoryService } from "@/store/category/CategoryService";
import { Category } from "@/models/category/category";
import map from "lodash/map";
import filter from "lodash/filter";
import find from "lodash/find";
import each from "lodash/each";
import { CustomerConfig } from "../../models/customer/CustomerConfig";
import moment from "moment";
import UploadFileButton from "@/components/upload-file/UploadFileButton.vue";
import { CustomerExportService } from "@/services/customer/CustomerExportService";
import CustomerQuickUpdate from "./CustomerQuickUpdate.vue";
import { GenderName } from "../../constants/gender";
import { ImportResult } from "@/models/import/ImportResult";

const customerStoreModule = namespace("customer");
@Component({
    components: {
        FilterList,
        AgGridVue,
        SelectPageSize,
        SettingColumns,
        UploadFileButton,
        CustomerQuickInsert: () => import(/*webpackChunkName: "CustomerQuickInsert"*/ "@/views/customer/CustomerQuickInsert.vue"),
        CustomerUpdateManager: () => import(/*webpackChunkName: "CustomerQuickInsert"*/ "@/views/customer/CustomerUpdateManager.vue"),
        CustomerQuickUpdate: () => import("@/views/customer/CustomerQuickUpdate.vue"),
        ImportResultModal: () => import(/*webpackChunkName: "ImportResultModal"*/ "@/views/import/ImportResultModal.vue"),
        CustomerUpdateSalesChannel: () => import(/*webpackChunkName: "CustomerQuickInsert"*/ "@/views/customer/CustomerUpdateSalesChannel.vue"),
    },
})

export default class CustomerList extends Vue {
    @Ref("agGridTable") agGridTable!: any;
    @Ref("customerQuickInsert") customerQuickInsert!: any;
    @Ref("customerQuickUpdate") customerQuickUpdate!: CustomerQuickUpdate;
    @Ref("customerUpdateSalesChannel") customerUpdateSalesChannel!: any;
    @Ref("filter") filter!: any;
    @Ref("selectPageSize") selectPageSize!: any;
    @Ref("settingColumnsRef") settingColumnsRef!: any;
    @Ref("customerImportResultModal") customerImportResultModal!: any;
    @Ref("customerUpdateManager") customerUpdateManagerModal!: any;
    @customerStoreModule.Action("fetchConfig") fetchConfig!: any;
    searchQuery = "";
    gridOptions: any = {};
    maxPageNumbers = 7;
    gridApi: any = null;
    defaultColDef = {
        sortable: false,
        editable: false,
        resizable: true,
        suppressMenu: true,
    };
    columnDefs: any = null;
    totalPages = 0;
    totalRows = 0;
    frameworkComponents: any = null;
    customers: CustomerResult[] = [];
    currentPage = 1;
    attributeOptions: FilterAttributeResult[] = [];

    // togge sidebar
    wasSidebarOpen: any = null;

    //setting column
    defaultSettingColumns: any[] = [];
    settingColumnConfig: SettingColumnResult = new SettingColumnResult();

    // Search loadOption
    loadOptions: QueryLoadOption = new QueryLoadOption();
    attributeSearchByKeywords = ["code", "phoneNumber", "normalizedName"];
    selectFieldRequired: string[] = ["id", "customerId", "image", "createTime", "concurrencyStamp", "isApproved"];
    listCustomTab: any[] = [];
    isShowCustomTabContent = false;
    listCustomerResource: Category[] = [];
    listCustomerClassify: Category[] = [];
    listCustomerSalesStrategy: Category[] = [];
    listCustomerSelected: CustomerResult[] = [];
    listImportResult: ImportResult[] = [];

    categoryType = CategoryType;
    userConfig: CustomerConfig = new CustomerConfig();
    categoryTypeUpdate: CategoryType;
    customerSelect: CustomerResult;
    isImporting: boolean = false;
    isSalesChannelLeader: boolean = false;

    private customerService: CustomerService;
    private settingColumnService: SettingColumnService = new SettingColumnService();

    constructor() {
        super();
        this.customerService = new CustomerService();
        this.loadOptions.sort = [new SortingInfo("createTime")];
    }

    beforeMount() {
        this.listCustomTab = [
            // {id: 'customerReport', title: this.$t('Report'), filter: null},
            {
                id: "notApprove",
                title: "Khách hàng chờ duyệt",
                filter: this.getFilterNotApprove(),
            },
        ];

        this.frameworkComponents = {
            CellRendererActive,
            CellRendererLink,
            // CellRendererAddress,
            CellRenderType,
            CustomValueRowRenderer,
            CellRendererApprove,
            CellRendererStatus,
            "floatingSelectFilter": FloatingSelectFilter,
            "dropdownCellRenderer": DropdownCellRenderer,
            "cellSelectOptionEditor": CellSelectOptionEditor,
        };

        this.defaultSettingColumns = this.getDefaultColumnSetting();
        this.gridOptions = {
            localeText: {noRowsToShow: "Không tìm thấy khách hàng nào"},
        };
        this.initial();
    }

    mounted() {
        setTimeout(() => {
            this.userConfig = this.$store.getters["customer/config"];
        }, 4000)
        this.renderAttributeOptions();
        this.gridApi = this.gridOptions.api;
        this.gridOptions.suppressRowTransform = true;
        //this.gridApi.sizeColumnsToFit();
        /* =================================================================
          NOTE:
          Header is not aligned properly in RTL version of agGrid table.
          However, we given fix to this issue. If you want more robust solution please contact them at gitHub
        ================================================================= */
        if (this.$vs.rtl) {
            const header = this.agGridTable.$el.querySelector(".ag-header-container");
            header.style.left = "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
        }

        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
    }

    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit("TOGGLE_REDUCE_BUTTON", false)
    }

    get paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 20;
    }

    get rowData() {
        return this.customers;
    }

    @Watch("currentPage")
    onCurrentPageChange(value: number, oldValue: number) {
        this.search(false);
    }

    @Watch("userConfig.isSalesChannelLeader")
    getSalesChannelLeader(value: boolean) {
        this.isSalesChannelLeader = value;
    }

    onImportPersonalFileChange(e: any) {
        const file = e.target.files;
        if (file) {
            this.isImporting = true;
            this.customerImportResultModal.show();
            new CustomerExportService().importPersonal(file[0])
                .then((result: ImportResult[]) => {
                    this.isImporting = false;
                    this.listImportResult = result;
                    this.search();
                });
        }
    }

    onImportEnterpriseFileChange(e: any) {
        const files = e.target.files;
        if (files) {
            this.isImporting = true;
            this.customerImportResultModal.show();
            new CustomerExportService().importEnterprise(files[0])
                .then((result: ImportResult[]) => {
                    this.isImporting = false;
                    this.listImportResult = result;
                    this.search();
                }).catch(() => this.isImporting = false);
        }
    }

    onImportIGFileChange(e: any) {
        const files = e.target.files;
        if (files) {
            this.isImporting = true;
            this.customerImportResultModal.show();

            new CustomerExportService().importIGreen(files[0])
                .then((result: ImportResult[]) => {
                    this.isImporting = false;
                    this.listImportResult = result;
                    this.search();
                }).catch(() => this.isImporting = false);
        }
    }

    onImportContactFileChange(e: any) {
        const files = e.target.files;
        if (files) {
            this.isImporting = true;
            this.customerImportResultModal.show();
            new CustomerExportService().importContact(files[0])
                .then((result: ImportResult[]) => {
                    this.isImporting = false;
                    this.listImportResult = result;
                    this.search();
                });
        }
    }

    initial() {
        /// Lấy danh mục
        const categoryService = new CategoryService();
        categoryService.getByTypes([CategoryType.CustomerClassify, CategoryType.CustomerResources, CategoryType.CustomerSalesStrategy])
            .then((result: SearchResult<Category>) => {
                if (result.code > 0) {
                    const items = result.items;
                    this.listCustomerResource = items.filter((category: Category) => {
                        return category.type === CategoryType.CustomerResources;
                    });

                    this.listCustomerClassify = items.filter((category: Category) => {
                        return category.type === CategoryType.CustomerClassify;
                    });

                    this.listCustomerSalesStrategy = items.filter((category: Category) => {
                        return category.type === CategoryType.CustomerSalesStrategy;
                    });

                    this.getColumnDefaultApi();

                    this.settingColumnService.getByModuleName("Customer")
                        .then((result: ActionResult<SettingColumnResult>) => {
                            this.settingColumnConfig = result.data as SettingColumnResult;
                            if (!this.settingColumnConfig) {
                                this.settingColumnConfig = new SettingColumnResult("Customer");
                                this.settingColumnConfig.columnConfigData = this.defaultSettingColumns;
                            }

                            this.getFieldSelectFromResult();
                            this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);

                            const filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);
                            if (!filterState || filterState.searchValue !== null) {
                                this.search();
                            }

                            if (this.gridApi) {
                                this.gridApi.refreshCells();
                            }
                        });
                }
            });
    }

    downloadCustomer() {

    }

    async downloadTemplate(type: number) {
        this.openLoading(true);
        const result = await new CustomerExportService().downloadTemplate(type);
        this.openLoading(false);
    }

    /**
     *
     * @param params
     */
    onCellValueChanged(params: any) {
        const colId = params.column.getId();
        let oldValue = params.oldValue;
        let newValue = params.newValue;
        if (oldValue) {
            oldValue = oldValue.trim();
        }
        if (newValue) {
            newValue = newValue.trim();
        }
        if (oldValue === newValue) {
            return;
        }

        const data = params.data;
        if (colId === "description") {
            this.customerService.updateDescription(data.id, newValue, data.concurrencyStamp)
                .then((result: ActionResult<string>) => {
                    if (result.code <= 0) {
                        this.$vs.notify({
                            color: "danger",
                            title: "Thông báo",
                            text: result.message,
                        })
                    } else {
                        this.$vs.notify({
                            color: "success",
                            title: "Thông báo",
                            text: result.message,
                        })
                        data.concurrencyStamp = result.data;
                    }
                }).catch((e) => {
                this.$vs.notify({
                    color: "danger",
                    title: "Thông báo",
                    text: e.message,
                })
            });
        }
        if (colId === "resourceName") {
            const categoryInfo = this.listCustomerResource.find((category: Category) => {
                return category.name === data.resourceName;
            });
            if (categoryInfo) {
                data.resourceId = categoryInfo.id;
                this.updateResource(data.id, data, data.concurrencyStamp);
            }
        }
        if (colId === "classifyName") {
            const categoryInfo = this.listCustomerClassify.find((category: Category) => {
                return category.name === data.classifyName;
            });
            if (categoryInfo) {
                data.classifyId = categoryInfo.id;
                this.updateClassify(data.id, data, data.concurrencyStamp);
            }
        }

        if (colId === "strategyName") {
            const categoryInfo = this.listCustomerSalesStrategy.find((category: Category) => {
                return category.name === data.strategyName;
            });
            if (categoryInfo) {
                data.strategyId = categoryInfo.id;
                this.updateStrategy(data.id, data, data.concurrencyStamp);
            }
        }
    }

    onCustomTabSelected(tab: any) {
        if (tab) {
            this.isShowCustomTabContent = true;
            this.$router.push({
                name: tab.id,
                params: tab.params,
            });
        }
    }

    onSortChange(e: any) {
        const columnSort = e.api.getSortModel();
        if (columnSort && columnSort[0]) {
            this.loadOptions.sort = [new SortingInfo(columnSort[0].colId, columnSort[0].sort === "desc")];

            this.setFilterState(this.currentPage, this.paginationPageSize, this.loadOptions);
            this.search(false);
        }
    }

    onRowDragEnd(e: any) {
        //this.gridApi.resetRowHeights()
    }

    addCustomer() {
        this.customerQuickInsert.add();
    }

    showDetail(customerResult: CustomerResult) {
        if (customerResult) {
            this.$router.push({name: "customerDetail", params: {id: customerResult.id}});
        }
    }

    deleteCustomer(customerResult: CustomerResult) {
        this.customerSelect = customerResult;
        this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: this.$t("ConfirmDelete"),
            acceptText: this.$t("Accept"),
            cancelText: this.$t("Cancel"),
            text: this.$t("ConfirmDeleteText", {data: customerResult.name}),
            accept: this.delete,
        })
    }

    delete() {
        if (this.customerSelect) {
            this.openLoading(true);
            this.customerService.delete(this.customerSelect.id)
                .finally(() => {
                    this.openLoading(false);
                })
                .then((result: ActionResult) => {
                    this.$vs.notify({
                        color: result.code <= 0 ? "danger" : "success",
                        title: "Thông báo",
                        text: result.message,
                    })

                    if (result.code > 0) {
                        this.search();
                    }
                }).catch((err: any) => {
                this.$vs.notify({
                    color: "danger",
                    title: "Thông báo",
                    text: err.message,
                })
            })
        }
    }

    changePageSize(pageSize: number) {
        this.gridApi.paginationSetPageSize(pageSize);
        this.currentPage = 1;

        this.setFilterState(this.currentPage, pageSize, this.loadOptions);
        this.search();
    }

    beginSearch(page: number) {
        if (this.searchQuery == undefined || this.searchQuery.length < 2) {
            return;
        }
        this.search();
    }

    searchByFilter(filterQuery: string) {
        this.isShowCustomTabContent = false;
        this.loadOptions.filterQuery = filterQuery;
        this.currentPage = 1;
        this.search();
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    // Setting clumn
    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    // Cập nhật column
    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.getFieldSelectFromResult();
        this.search();
    }

    async exportExcel() {
        this.openLoading(true);
        const result = await this.customerService.exportExcel(this.loadOptions);
        this.openLoading(false);
    }

    // Show bộ lọc
    showModalFilter(value: FilterAttributeResult[]) {
        if (!value || value.length === 0) {
            this.renderAttributeOptions();
        }
    }

    // Thêm khách hàng thành công
    onSaveSuccess(value: any) {
        this.search();
    }

    // Duyệt khách hàng
    approveCustomer() {
        const listCustomerNotApprove = filter(this.listCustomerSelected, (item: CustomerResult) => {
            return !item.isApproved;
        })

        if (this.listCustomerSelected && this.listCustomerSelected.length > 0
            && (!listCustomerNotApprove || listCustomerNotApprove.length === 0)) {
            this.$vs.notify({
                color: "danger",
                title: "Thông báo",
                text: "Vui lòng chọn khách hàng ở trạng thái chưa duyệt",
            })
        } else {
            this.$vs.dialog({
                type: "confirm",
                color: "primary",
                title: "Duyệt khách hàng?",
                acceptText: this.$t("Accept"),
                cancelText: this.$t("Cancel"),
                text: !listCustomerNotApprove || listCustomerNotApprove.length === 0 ? "Bạn muốn duyệt tất cả khách hàng chưa duyệt?"
                    : this.$t("ConfirmApproveMessage", ["các khách hàng đã chọn"]),
                accept: this.approve,
            })
        }
    }

    approve() {
        const listCustomerNoApprove = filter(this.listCustomerSelected, (customer: CustomerResult) => {
            return !customer.isApproved
        });

        const listCustomerIdSelect = map(listCustomerNoApprove, (item: CustomerResult) => {
            return item.id;
        });

        try {
            this.$vs.loading({
                background: "primary",
                color: "white",
                container: "#btnSave",
                scale: 0.45,
            })

            this.customerService.updateStatusApproveCustomer(listCustomerIdSelect, true).then((result: ActionResult<number>) => {
                if (result.code > 0) {
                    this.$vs.notify({
                        title: "Thông báo",
                        text: result.message,
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-info",
                    });

                    this.$vs.loading.close("#btnSave > .con-vs-loading")

                    if (listCustomerIdSelect && listCustomerIdSelect.length > 0) {
                        each(this.customers, (customer: CustomerResult) => {
                            const customerInfo = find(this.listCustomerSelected, (item: CustomerResult) => {
                                return item.id === customer.id;
                            });

                            if (customerInfo) {
                                customerInfo.isApproved = true;
                            }
                        })
                        this.gridApi.setRowData(this.customers);
                    } else {
                        this.search();
                    }
                    //this.listCustomerSelected = [];
                }
            })
        } catch (e) {
            this.$vs.notify({
                title: "",
                text: e.message,
                color: "danger",
            });
        }
    }

    // Chọn khách hàng
    onSelectionChanged(event: any) {
        this.listCustomerSelected = event.api.getSelectedRows();
    }

    showCustomerQuickUpdate(type: number) {
        this.categoryTypeUpdate = type;
        if (!this.listCustomerSelected || this.listCustomerSelected.length == 0) {
            this.$vs.notify({
                color: "danger",
                title: "Thông báo",
                text: "Vui lòng chọn khách hàng.",
            })
        } else {
            const customerIds = map(this.listCustomerSelected, (item: CustomerResult) => {
                return item.id
            });
            this.customerQuickUpdate.show(type, customerIds);
        }
    }

    showCustomerQuickUpdateSalesChannel() {
        if (!this.listCustomerSelected || this.listCustomerSelected.length == 0) {
            this.$vs.notify({
                color: "danger",
                title: "Thông báo",
                text: "Vui lòng chọn khách hàng.",
            })
        } else {
            const customerIds = map(this.listCustomerSelected, (item: CustomerResult) => {
                return item.id
            });
            this.customerUpdateSalesChannel.show(customerIds);
        }
    }

    showCustomerQuickUpdateManager() {
        if (!this.listCustomerSelected || this.listCustomerSelected.length == 0) {
            this.$vs.notify({
                color: "danger",
                title: "Thông báo",
                text: "Vui lòng chọn khách hàng.",
            })
        } else {
            const customerIds = map(this.listCustomerSelected, (item: CustomerResult) => {
                return item.id
            });

            this.customerUpdateManagerModal.show(customerIds);
        }
    }

    onSaveSuccessQuickUpdate(value: any) {
        each(this.customers, (customer: CustomerResult) => {
            const customerInfo = find(this.listCustomerSelected, (item: CustomerResult) => {
                return item.id === customer.id;
            });

            if (customerInfo) {
                switch (this.categoryTypeUpdate) {
                    case CategoryType.CustomerClassify:
                        customerInfo.classifyId = value.id;
                        customerInfo.classifyName = value.name;
                        break;
                    case CategoryType.CustomerResources:
                        customerInfo.resourceId = value.id;
                        customerInfo.resourceName = value.name;
                        break;
                    case CategoryType.CustomerSalesStrategy:
                        customerInfo.strategyId = value.id;
                        customerInfo.strategyName = value.name;
                        break;
                    default:
                        this.search();
                }
            }
        })
        this.gridApi.setRowData(this.customers);
        this.listCustomerSelected = [];
    }

    onSaveSuccessManager() {
        this.search(false);
    }

    onSaveSuccessSalesChannel() {
        this.search(false);
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }

    // Tìm kiếm
    private search(isGetTotal = true) {
        this.openLoading(true);
        const filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);
        if (filterState) {
            this.loadOptions.filterQuery = filterState.loadOption.filterQuery;
            this.gridApi.paginationSetPageSize(filterState.pageSize);
        }

        this.loadOptions.skip = (this.currentPage - 1) * this.paginationPageSize;
        this.loadOptions.take = this.paginationPageSize;
        this.loadOptions.remoteSelect = false;
        this.customerService.search(this.loadOptions).finally(() => {
            this.openLoading(false);
        }).then((result: SearchResult<CustomerResult>) => {
            this.customers = result.items;
            // this.customers = this.renderResult(result.items);
            //this.totalRows = result.totalRows;
            this.listCustomerSelected = [];

            this.setFilterState(this.currentPage, this.paginationPageSize);
            //this.gridApi.setRowData(this.customers);
            setTimeout(() => {
                if (isGetTotal) {
                    this.customerService.countByQuery(this.loadOptions).then((result: number) => {
                        this.setTotalPage(result);
                    })
                } else {
                    this.setTotalPage(this.totalRows);
                }
            }, 200)
        });
    }

    private renderResult(customers: CustomerResult[]) {
        const listCustomer: CustomerResult[] = [];
        each(customers, (customer: CustomerResult) => {
            listCustomer.push(customer);
            if (customer.contacts && customer.contacts.length > 0) {
                each(customer.contacts, (item: CustomerResult) => {
                    item.customerId = customer.id;
                    listCustomer.push(item);
                });
            }
        });

        return listCustomer;
    }

    /// Set total Page
    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.paginationPageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.paginationPageSize));
        }
    }

    /// Tạo các thuộc tính tìm kiếm
    private renderAttributeOptions() {
        this.attributeOptions = this.customerService.getCustomerAttributeOptions();
    }

    private getColumnDefaultApi() {
        this.columnDefs = [
            {
                headerName: "",
                field: "actions",
                filter: false,
                width: 50,
                pinned: "left",
                suppressSizeToFit: true,
                cellRenderer: "dropdownCellRenderer",
                headerClass: "items-center",
                cellClass: "flex content-center",
                cellRendererParams: {
                    source: [
                        {
                            icon: "AlignJustifyIcon",
                            text: "Chi tiết",
                            svgClass: "fill-current text-success text-xs",
                            onClick: this.showDetail,
                        },
                        {
                            icon: "Trash2Icon",
                            text: "Xóa",
                            svgClass: "text-danger text-xs",
                            onClick: this.deleteCustomer,
                        },
                    ],
                },
            },
            {
                headerName: "",
                field: "selection",
                filter: false,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: true,
                width: 50,
                pinned: "left",
                suppressSizeToFit: true,
            },
            {
                headerName: this.$t("Customer.code"),
                field: "code",
                filter: true,
                sortable: false,
                width: 150,
                pinned: "left",
                suppressSizeToFit: true,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.name"),
                field: "name",
                filter: true,
                sortable: false,
                width: 350,
                pinned: "left",
                suppressSizeToFit: true,
                editable: false,
                autoHeight: true,
                cellRenderer: "CellRendererLink",
                cellClass: "whitespace-normal",
            },
            {
                headerName: this.$t("Customer.shortName"),
                field: "shortName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.phoneNumber"),
                field: "phoneNumber",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.email"),
                field: "email",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.salesChannelName"),
                field: "salesChannelName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.addressPrimary.provinceName"),
                field: "addressPrimary.provinceName",
                filter: true,
                // sortable: false,
                resizable: true,
                width: 150,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.addressPrimary.districtName"),
                field: "addressPrimary.districtName",
                filter: true,
                // sortable: false,
                resizable: true,
                width: 150,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.addressPrimary.address"),
                field: "addressPrimary.address",
                filter: true,
                // sortable: false,
                resizable: true,
                width: 300,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.managerFullName"),
                field: "managerFullName",
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.website"),
                field: "website",
                filter: true,
                // sortable: false,
                width: 200,
                editable: false,
                cellRenderer: function (params: any) {
                    if (params.value) {
                        return `<a href="${params.value}" target="_blank" class="text-sm">` + params.value + "</a>";
                    } else {
                        return "";
                    }
                },
            },
            {
                headerName: this.$t("Customer.taxCode"),
                field: "taxCode",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.creatorFullName"),
                field: "creatorFullName",
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.representative"),
                field: "representative",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.description"),
                field: "description",
                filter: true,
                sortable: false,
                width: 300,
                editable: true,
                autoHeight: true,
                cellRenderer: "CustomValueRowRenderer",
                cellEditor: "agLargeTextCellEditor",
            },
            {
                headerName: this.$t("Customer.foundingDate"),
                field: "foundingDate",
                filter: true,
                sortable: false,
                width: 160,
                editable: false,
                cellRenderer: function (params: any) {
                    if (params.value) {
                        if (params.data.customerId) {
                            return `<i class="text-sm">` + moment(params.value).format("DD-MM-YYYY") + `</i>`
                        } else {
                            return `<span class="text-sm">` + moment(params.value).format("DD-MM-YYYY") + `</span>`
                        }
                    }
                },
            },
            {
                headerName: this.$t("Customer.gender"),
                field: "gender",
                filter: true,
                sortable: false,
                width: 160,
                editable: false,
                cellRenderer: function (params: any) {
                    if (params.value !== null) {
                        if (params.data.customerId) {
                            return `<i class="text-sm">` + new GenderName(params.value).name + `</i>`
                        } else {
                            return `<span class="text-sm">` + new GenderName(params.value).name + `</span>`
                        }
                    }
                },
            },
            {
                headerName: this.$t("Customer.idCardNumber"),
                field: "idCardNumber",
                filter: true,
                sortable: false,
                width: 160,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.createTime"),
                field: "createTime",
                filter: true,
                sortable: false,
                width: 160,
                editable: false,
                cellRenderer: function (params: any) {
                    return `<i class="text-sm">` + moment(params.value).format("DD-MM-YYYY HH:mm") + `</i>`
                },
            },
            // {
            //     headerName: this.$t('Customer.isActive'),
            //     field: 'isActive',
            //     floatingFilterComponent: 'floatingSelectFilter',
            //     cellRenderer: "CellRendererActive",
            //     editable: false,
            //     width: 100,
            //     filter: true,
            //     cellStyle: {textAlign: "center"}
            // },
            {
                headerName: this.$t("Customer.status"),
                field: "status",
                cellRenderer: "CellRendererStatus",
                editable: false,
                width: 100,
                filter: true,
            },
            {
                headerName: this.$t("Customer.type"),
                field: "type",
                cellRenderer: "CellRenderType",
                editable: false,
                width: 200,
                filter: true,
            },
            {
                headerName: this.$t("Customer.resourceName"),
                field: "resourceName",
                filter: true,
                sortable: false,
                width: 200,
                editable: true,
                cellRenderer: "CustomValueRowRenderer",
                cellEditor: "cellSelectOptionEditor",
                cellEditorParams: {
                    source: this.listCustomerResource.map((item: Category) => {
                        return item.name;
                    }),
                },
            },
            {
                headerName: this.$t("Customer.classifyName"),
                field: "classifyName",
                filter: true,
                sortable: false,
                width: 200,
                editable: true,
                cellRenderer: "CustomValueRowRenderer",
                cellEditor: "cellSelectOptionEditor",
                cellEditorParams: {
                    source: this.listCustomerClassify.map((item: Category) => {
                        return item.name;
                    }),
                },
            },
            {
                headerName: this.$t("Customer.potentialLevelName"),
                field: "potentialLevelName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.clarificationName"),
                field: "clarificationName",
                filter: true,
                sortable: false,
                width: 250,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.strategyName"),
                field: "strategyName",
                filter: true,
                sortable: false,
                width: 200,
                editable: true,
                cellRenderer: "CustomValueRowRenderer",
                cellEditor: "cellSelectOptionEditor",
                cellEditorParams: {
                    source: this.listCustomerSalesStrategy.map((item: Category) => {
                        return item.name;
                    }),
                },
            },
            {
                headerName: this.$t("Customer.focusGroupName"),
                field: "focusGroupName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.companySizeName"),
                field: "companySizeName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.businessCareerName"),
                field: "businessCareerName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.revenue"),
                field: "revenueName",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CustomValueRowRenderer",
            },
            {
                headerName: this.$t("Customer.isApproved"),
                field: "isApproved",
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: "CellRendererApprove",
            },
        ];
    }

    private getDefaultColumnSetting() {
        return this.customerService.getDefaultColumnSetting();
    }

    private getFieldSelectFromResult() {
        const columnSelect = filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
            return item.isSelected;
        });

        const fieldSelect = map(columnSelect,
            (item: SettingColumnSelect) => {
                return item.field
            });

        this.loadOptions.select = this.selectFieldRequired.concat(fieldSelect).filter((idx: string) => {
            return idx !== "actions" && idx !== "selection";
        });

        if (!fieldSelect || fieldSelect.length < 5) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    private setFilterState(currentPage: number, pageSize: number, loadOption?: QueryLoadOption) {
        let filterState: FilterStateResult = this.$store.getters["filter/getFilter"](this.$route.fullPath);
        if (!filterState) {
            filterState = new FilterStateResult();
        }

        filterState.pageSize = pageSize;
        filterState.currentPage = currentPage;
        filterState.loadOption = loadOption ? loadOption : filterState.loadOption;
        filterState.module = this.$route.fullPath;
        filterState.attributeOptions = this.attributeOptions;

        // Cập nhật lại filter state
        this.$store.dispatch("filter/setFilterState", filterState);
    }

    private updateResource(id: any, data: any, concurrencyStamp: any) {
        const customerService = new CustomerService();
        customerService.updateResource(id, data.resourceId, data.resourceName, concurrencyStamp)
            .then((result: ActionResult<string>) => {
                if (result.code <= 0) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Thông báo",
                        text: result.message,
                    })
                } else {
                    this.$vs.notify({
                        color: "success",
                        title: "Thông báo",
                        text: result.message,
                    })
                    data.concurrencyStamp = result.data;
                }
            })
            .catch((e: any) => {
                this.$vs.notify({
                    color: "danger",
                    title: "Thông báo",
                    text: e.message,
                })
            });
    }

    private updateClassify(id: any, data: any, concurrencyStamp: any) {
        const customerService = new CustomerService();
        customerService.updateClassify(id, data.classifyId, data.classifyName, concurrencyStamp)
            .then((result: ActionResult<string>) => {
                if (result.code <= 0) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Thông báo",
                        text: result.message,
                    })
                } else {
                    this.$vs.notify({
                        color: "success",
                        title: "Thông báo",
                        text: result.message,
                    })
                    data.concurrencyStamp = result.data;
                }
            })
            .catch((e: any) => {
                this.$vs.notify({
                    color: "danger",
                    title: "Thông báo",
                    text: e.message,
                })
            });
    }

    private updateStrategy(id: any, data: any, concurrencyStamp: any) {
        const customerService = new CustomerService();
        customerService.updateSalesStrategy(id, data.strategyId, data.strategyName, concurrencyStamp)
            .then((result: ActionResult<string>) => {
                if (result.code <= 0) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Thông báo",
                        text: result.message,
                    })
                } else {
                    data.concurrencyStamp = result.data;
                }
            })
            .catch((e: any) => {
                this.$vs.notify({
                    color: "danger",
                    title: "Thông báo",
                    text: e.message,
                })
            });
    }

    private getFilterNotApprove() {
        return {
            id: "",
            name: "Khách hàng chờ duyệt",
            routerName: "Customer",
            userId: "",
            fullName: "",
            operatorJson: "",
            concurrencyStamp: "",
            attributes: [
                {
                    id: "",
                    attributeId: "IsApproved",
                    attributeName: "Trạng thái duyệt",
                    dataType: DataType.Bit,
                    type: AttributeType.CheckList,
                    isDefault: true,
                    isMultiple: true,
                    operator: LogicType.And,
                    values: [
                        {
                            id: false,
                            Name: "Không duyệt",
                            Operator: OperatorType.Equal,
                            Logic: LogicType.Or,
                        }, {
                            id: "null",
                            name: "Chờ duyệt",
                            operator: OperatorType.Equal,
                            logic: LogicType.Or,
                        },
                    ],
                    logic: LogicType.And,
                    operatorJson: "",
                    concurrencyStamp: "",
                    scopes: [],
                },
            ],
        }
    }
}
