import axios from 'axios';
import store from "@/store";
import {ImportResult} from "@/models/import/ImportResult";

export class CustomerExportService {
    url = `${process.env.VUE_APP_GATEWAY_URL}customer/api/v1/customer-exports`;

    async downloadTemplate(type: number) {
        var result = (await axios.get(`${this.url}/templates/${type === 0 ? 'personal' : type === 1 ? 'enterprise' : 'contact'}`,
            {responseType: 'blob'})) as any;
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',
            `${type == 0 ? 'INKA CRM@Template khach ca nhan' : type == 1 ? 'INKA CRM@Template khach doanh nghiep'
                : 'INKA CRM@Template dau moi lien he'}.xlsx`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        return true;
    }

    async importPersonal(file: any) {
        const formData = new FormData();
        // @ts-ignore
        // console.log(store.state)
        // const token = store.state.oidcStore.access_token;
        // if (!token) {
        //     console.log('Access token not found');
        //     return [];
        // } else {
        //     const headers = {
        //         'Content-Type': 'multipart/form-data',
        //         // 'Authorization': `bearer ${token}`
        //     };
        //
        //     try {
        //         formData.append('file', file);
        //         const result: ImportResult[] = await axios.post(`${this.url}/import-personal`, formData, {
        //             headers
        //         });
        //         return result;
        //     } catch (e) {
        //         console.log(e);
        //         return [];
        //     }
        // }

        const headers = {
            'Content-Type': 'multipart/form-data',
            // 'Authorization': `bearer ${token}`
        };

        try {
            formData.append('file', file);
            const result: ImportResult[] = await axios.post(`${this.url}/import-personal`, formData, {
                headers
            });
            return result;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    async importEnterprise(file: any): Promise<ImportResult[]> {
        // @ts-ignore
        const token = store.state.oidcStore.access_token;
        if (!token) {
            console.log('Access token not found');
            return [];
        } else {
            const formData = new FormData();
            const headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `bearer ${token}`
            };

            try {
                formData.append('file', file);
                const result: ImportResult[] = await axios.post(`${this.url}/import-enterprise`, formData, {
                    headers
                });
                return result;
            } catch (e) {
                console.log(e);
                return [];
            }
        }
    }

    async importIGreen(file: any): Promise<ImportResult[]> {
        // @ts-ignore
        const token = store.state.oidcStore.access_token;
        if (!token) {
            console.log('Access token not found');
            return [];
        } else {
            const formData = new FormData();
            const headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `bearer ${token}`
            };

            try {
                formData.append('file', file);
                const result: ImportResult[] = await axios.post(`${this.url}/import-ig`, formData, {
                    headers
                });
                return result;
            } catch (e) {
                console.log(e);
                return [];
            }
        }
    }

    async importContact(file: any): Promise<ImportResult[]> {
        // @ts-ignore
        const token = store.state.oidcStore.access_token;
        if (!token) {
            console.log('Access token not found');
            return [];
        } else {
            const formData = new FormData();
            const headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `bearer ${token}`
            };

            try {
                formData.append('file', file);
                const result: ImportResult[] = await axios.post(`${this.url}/import-contact`, formData, {
                    headers
                });
                return result;
            } catch (e) {
                console.log(e);
                return [];
            }
        }
    }
}
