







import {Component, Ref, Vue} from "vue-property-decorator";

@Component
export default class CellSelectOptionEditor extends Vue {
    @Ref('inputElement')
    inputElement!: any;
    params: any = null;
    value: any = '';

    get source() {
        return this.params.source;
    }

    mounted() {
        this.value = this.params.value;
    }

    onValueChange() {
        console.log(this.value);
        //this.value = value;
        this.params.api.stopEditing();
    }

    getValue(): any {
        return this.value;
    }

    isPopup(){
    }

    isCancelBeforeStart() {

    }

    isCancelAfterEnd() {

    }
}
