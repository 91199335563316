






import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class CellRendererApprove extends Vue {
    params: any;

    get chipColor() {
        return (value?: boolean) => {
            if (value === true) return "success";
            else if(value === false) return "danger";
            else return "primary"
        }
    }

    get approveName() {
        return (value?: boolean) => {
            if (value === true) return "Đã duyệt";
            else  if(value === false)
                return "Không duyệt"
            else return "Chờ duyệt";
        }
    }
}
