






import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class CellRendererActive extends Vue {
    params: any;

    get chipColor() {
        return (value: any) => {
            if (value === true) return "success";
            else if (value === false) return "danger";
            else return "primary";
        }
    }

    get statusName() {
        return (value: any) => {
            if (value === false) return "InActive";
            else if (value === true) return "Active";
            else return "";
        }
    }
}
