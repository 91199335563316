










import {Component, Vue} from 'vue-property-decorator';
import {CustomerType} from "@/constants/Customer";

@Component({})
export default class CellRendererLink extends Vue {
    params: any;
    customerType = CustomerType;

    get url() {
        return `customer/detail/${this.params.data.id}`
    }

    showDetail() {
        if (this.params.data.id) {
            this.$router.push({name: 'customerDetail', params: {id: this.params.data.id}});
        }
    }
}
