






import {Component, Vue, Watch} from 'vue-property-decorator';

@Component
export default class FloatingSelectFilter extends Vue {
    params: any;
    filterValue: any = '';

    @Watch('filterValue')
    onValueChange(value: any, oldValue: any) {
        this.params.parentFilterInstance((instance: any) => {
            instance.onFloatingFilterChanged('equals', value);
        });

    }

    get source() {
        return this.params.source;
    }

    onParentModelChanged(parentModel: any) {
        // note that the filter could be anything here, but our purposes we're assuming a greater than filter only,
        // so just read off the value and use that
        this.filterValue = !parentModel ? '' : parentModel.filter;
    }
}
