






import {Component, Vue} from 'vue-property-decorator';
import {CustomerType} from "@/constants/Customer";

@Component({})
export default class CellRenderType extends Vue {
    params: any;

    get chipColor() {
        return (value: number) => {
            if (value === CustomerType.personal) return "success";
            else if (value === CustomerType.company) return "primary";
            else return "dark";
        }
    }

    get typeName() {
        return (value: number) => {
            if (value === CustomerType.company) return "Doanh nghiệp";
            else if (value === CustomerType.personal) return "Cá nhân";
            else return "";
        }
    }
}
