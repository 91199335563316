










import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class CustomValueRowRenderer extends Vue {
    params: any;
    editable: boolean = false;
    headerName: string = '';

    mounted() {
        try {
            this.editable = this.params.colDef.editable;
            this.headerName = this.params.colDef.headerName.toLowerCase();
        } catch (e) {
            this.editable = false;
        }
    }
}
